import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=aa1f261a&scoped=true&"
import script from "./Error404.vue?vue&type=script&lang=js&"
export * from "./Error404.vue?vue&type=script&lang=js&"
import style0 from "./Error404.vue?vue&type=style&index=0&id=aa1f261a&prod&scoped=true&lang=css&"
import style1 from "@/assets/css/styles.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1f261a",
  null
  
)

export default component.exports