<!-- =========================================================================================
    File Name: Error404.vue
    Description: 404 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="min-h-screen flex w-full background">
    <div class="vx-col flex items-center justify-center flex-col sm:w-3/4 mx-auto text-center p-6">
      <img src="@/assets/images/logo.svg" alt="graphic-404" class="mx-auto mb-7 max-w-full w-96">
      <p class="mb-1 md:text-4xl text-3xl">Oops! We couldn't find this Magic link in our records!</p>
      <p class="mb-4 md:text-xl text-lg">The magic link might be either misspelled or does not exist. Please, check with with your merchant.</p>
      <p class="mb-2 md:text-2xl text-xl">Want to sell faster on social media? Sign up on ShipBlu and 
        <a href="https://shipblu.com/get-started-with-shipblu/?ref=magic" target="_blank" class="mb-20 underline text-blue cursor-pointer">create your own Magic links now</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    
  }
}
</script>

<style scoped>
.background {
  direction: ltr !important;
  background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url('../assets/images/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
#fc_frame {
  display: none;
}
</style>
<style lang="scss" src="@/assets/css/styles.scss">
</style>